<script lang="ts" setup>
    import {computed} from 'vue';
    import useCpBem from '~/composables/useCpBem';
    import Cyberpuerta from '~/assets/img/cyberpuerta.svg';
    import CyberpuertaWhite from '~/assets/img/cyberpuerta_white.png';
    import Isotype from '~/assets/img/isotype.png';
    import IsotypeWhite from '~/assets/img/isotype_white.png';

    const props = withDefaults(
        defineProps<{
            /** Custom description */
            imagoType?: boolean,
            primary?: boolean,
        }>(), {
            imagoType: false,
            primary: true,
        },
    );

    const img = computed(() => {
        if (props.imagoType) {
            if (props.primary) {
                return Isotype;
            }
            return IsotypeWhite;
        }
        if (props.primary) {
            return Cyberpuerta;
        }
        return CyberpuertaWhite;
    });

    const {bm} = useCpBem('cp-logo');
</script>

<template>
    <v-img :class="bm({isotype: imagoType})" :src="img" :alt="img" />
</template>

<style scoped lang="scss">
    .cp-logo {
        width: 264px;
        &--isotype{
            width: 28px;
        }
    }
</style>
